/** @jsxImportSource @emotion/react */
import React, { useState, useRef } from "react";
import axios from "axios";
import { css } from "@emotion/react";
import { getPublicStudies, getPublicStudy } from "../lichess/get_studies";

export const ReviewOpening = () => {
  const initialFile: any = "";
  const intialResults: any = "";
  const initialSelectedStudy: any = "";
  const initialOpeningStudyByID: any = "";
  const initialStudies: any[] = [];
  const [openingFile, setOpeningFile] = useState(initialFile);
  const [username, setUsername] = useState("");
  const [gameFile, setGameFile] = useState(initialFile);
  const [gamePGN, setGamePGN] = useState("");
  const [results, setResults] = useState(intialResults);
  const [processing, setProcessing] = useState(false);
  const [processingDots, setProcessingDots] = useState("");
  const [openingStudyID, setOpeningStudyID] = useState("");
  const [openingStudyByID, setOpeningStudyByID] = useState(
    initialOpeningStudyByID
  );
  const [lichessStudies, setlichessStudies] = useState(initialStudies);
  const [selectedLichessStudy, setSelectedLichessStudy] =
    useState(initialSelectedStudy);
  const [selectedColor, setSelectedColor] = useState("white");
  const openingFileInputRef = useRef<HTMLInputElement>(null);

  function handleOpeningFileChange(event: any) {
    if (event.target.value.length > 0) {
      setOpeningFile(event.target.files[0]);
      setSelectedLichessStudy("");
      setOpeningStudyByID("");
    } else {
      setOpeningFile("");
    }
  }
  function handleGameFileChange(event: any) {
    if (event.target.value.length > 0) {
      setGameFile(event.target.files[0]);
    } else {
      setGameFile("");
    }
  }

  function processWithDots(duration: number) {
    let dots = "";
    let intervalId = setInterval(() => {
      dots += ".";
      if (dots.length > 9) {
        dots = "";
      }
      setProcessingDots("Processing" + dots);
    }, 500);

    setTimeout(() => {
      clearInterval(intervalId);
      console.log("Processing complete!");
    }, duration);
  }

  async function handleDoConsistencyCheck(color: string) {
    try {
      console.log(openingFile);
      console.log("env", process.env.NODE_ENV);
      if (
        openingStudyByID !== "" ||
        openingFile !== "" ||
        selectedLichessStudy !== ""
      ) {
        let openingText = "";

        let nameOfOpeningFile = "";

        if (openingFile !== "") {
          openingText = await openingFile.text();
          nameOfOpeningFile = openingFile.name;
        }
        if (selectedLichessStudy !== "") {
          openingText = await getPublicStudy(selectedLichessStudy.id);
          nameOfOpeningFile = selectedLichessStudy.name;
        }
        if (openingStudyByID !== "") {
          openingText = openingStudyByID;
          nameOfOpeningFile = openingStudyByID.split("\n")[0];
        }

        processWithDots(10000);
        setProcessing(true);

        const url =
          process.env.NODE_ENV === "production"
            ? "https://revchess.com/api/consistency_check"
            : "http://localhost:5000/api/consistency_check";
        const formData = new FormData();
        formData.append("opening_text", openingText);
        formData.append("color", selectedColor);
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        console.log("posting");
        const response = await axios.post(url, formData, config);
        console.log("back");

        setProcessing(false);
        console.log("Resp", response.data);
        let results = response.data;
        results =
          "Consistency Results for: " + nameOfOpeningFile + "\n" + results;
        setResults(results);
      } else {
        setResults("Please choose an opening file above");
      }
    } catch (e) {
      console.log(e);
    }
  }
  async function handleSubmit(event: any) {
    try {
      event.preventDefault();
      console.log(openingFile);
      console.log("env", process.env.NODE_ENV);
      if (
        (openingStudyByID !== "" ||
          openingFile !== "" ||
          selectedLichessStudy !== "") &&
        (gameFile !== "" || gamePGN !== "")
      ) {
        let gameText = "";
        if (gameFile !== "") {
          gameText = await gameFile.text();
        } else {
          gameText = gamePGN;
        }
        let openingText = "";

        let nameOfOpeningFile = "";

        if (openingFile !== "") {
          openingText = await openingFile.text();
          nameOfOpeningFile = openingFile.name;
        }
        if (selectedLichessStudy !== "") {
          openingText = await getPublicStudy(selectedLichessStudy.id);
          nameOfOpeningFile = selectedLichessStudy.name;
        }
        if (openingStudyByID !== "") {
          openingText = openingStudyByID;
          nameOfOpeningFile = openingStudyByID.split("\n")[0];
        }

        processWithDots(10000);
        setProcessing(true);

        const url =
          process.env.NODE_ENV === "production"
            ? "https://revchess.com/api/check_opening"
            : "http://localhost:5000/api/check_opening";
        const formData = new FormData();
        formData.append("opening_text", openingText);
        formData.append("game_text", gameText);
        formData.forEach((next) => console.log(next));
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        console.log("posting");
        const response = await axios.post(url, formData, config);
        console.log("back");

        setProcessing(false);
        console.log("Resp", response.data);
        let results = response.data;
        let gameUsed = gameFile === "" ? "Pasted PGN" : gameFile.name;
        results =
          "Opening: " +
          nameOfOpeningFile +
          "\n" +
          "Game(s):" +
          gameUsed +
          "\n" +
          results;
        setResults(results);
      } else {
        if (openingFile === "") {
          if (gameFile === "" && gamePGN === "") {
            setResults(
              "Please choose above an opening file and either a game file or paste the pgn of a game"
            );
          } else {
            setResults("Please choose an opening file above");
          }
        } else {
          setResults(
            "Please choose above a game file or paste the pgn of the game(s) in text area above (not here)"
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div css={containerCss}>
      <div>Opening (Select from public Lichess Studies or upload pgn file)</div>
      <div css={openingContainerCss}>
        <div>
          <label>Study ID:</label>
          <input
            value={openingStudyID}
            onChange={(e) => setOpeningStudyID(e.target.value)}
          ></input>
          <div>
            <button
              onClick={async () => {
                const studyByID = await getPublicStudy(openingStudyID);
                console.log("Study by id", studyByID);
                setOpeningStudyByID(studyByID);
                setSelectedLichessStudy("");
                setOpeningFile("");
                if (openingFileInputRef.current) {
                  openingFileInputRef.current.value = "";
                }
              }}
            >
              Get Study
            </button>
            <div>{openingStudyByID.split("\n")[0]}</div>
          </div>
        </div>
        <div> Or get list of studies of user: </div>
        <div>
          <label>Lichess Username:</label>
          <input
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          ></input>
          <button
            onClick={async () => {
              const studies = await getPublicStudies(username);
              setlichessStudies(studies);
            }}
          >
            Get Lichess Studies
          </button>
          <div>Select Opening Study:</div>
          <ul css={studyListCss}>
            <li
              onClick={() => {
                setSelectedLichessStudy("");
                if (openingFileInputRef.current) {
                  openingFileInputRef.current.value = "";
                }
              }}
              style={{
                backgroundColor:
                  selectedLichessStudy === "" ? "LightGray" : "white",
              }}
            >
              None...using file upload or study id
            </li>
            {lichessStudies.map((item: any) => (
              <li
                key={item.id}
                style={{
                  backgroundColor:
                    selectedLichessStudy === item ? "LightGray" : "white",
                }}
                onClick={() => {
                  setSelectedLichessStudy(item);
                  setOpeningFile("");
                  setOpeningStudyByID("");
                  if (openingFileInputRef.current) {
                    openingFileInputRef.current.value = "";
                  }
                }}
              >
                {item.name}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <label htmlFor="choose_opening">Or upload opening PGN File:</label>
          <input
            id="choose_opening"
            ref={openingFileInputRef}
            type="file"
            name="opening_file"
            onChange={handleOpeningFileChange}
          />
        </div>
        <div css={consistencyCheckCss}>
          <div>Opening's color:</div>
          <label>
            <input
              type="radio"
              value="white"
              checked={selectedColor === "white"}
              onChange={(e) => {
                setSelectedColor(e.target.value);
              }}
            ></input>
            white
          </label>
          <label>
            <input
              type="radio"
              value="black"
              checked={selectedColor === "black"}
              onChange={(e) => {
                setSelectedColor(e.target.value);
              }}
            ></input>
            black
          </label>
          <button
            onClick={() => {
              handleDoConsistencyCheck("black");
            }}
          >
            Perform Consistency Check
          </button>
        </div>
      </div>
      <div>Game(s) (Upload pgn File or Paste pgn)</div>

      <form onSubmit={(e) => handleSubmit(e)}>
        <div css={openingContainerCss}>
          <div>
            <label htmlFor="choose_game">Choose file containing game(s):</label>
            <input
              id="choose_game"
              type="file"
              name="game_file"
              onChange={handleGameFileChange}
            />
          </div>
          <div>
            <div>...or paste game(s) pgn here:</div>
            <textarea
              value={gamePGN}
              onChange={(e) => setGamePGN(e.target.value)}
              css={gamePGNCss}
            ></textarea>
          </div>
          <div>
            <button>Compare game(s) to opening</button>
          </div>
        </div>
      </form>
      <div>Results:</div>
      <textarea
        css={resultsCss}
        value={processing ? processingDots : results}
        readOnly
      ></textarea>
    </div>
  );
};

const studyListCss = css`
  list-style-type: none;
  text-align: left;
  border: solid 1px;
  max-height: 75px;
  overflow: auto;
  margin: 5px;
  padding: 0;
`;

const openingContainerCss = css`
  display: flex;
  flex-direction: column;
  gap: 5px;
  border: solid;
  max-width: 400px;
  margin: 5px;
  padding: 5px;
  margin-bottom: 25px;
`;

const containerCss = css`
  margin: 10px;
`;

const consistencyCheckCss = css`
  margin: 10px;
`;
const gamePGNCss = css`
  min-width: 370px;
`;

const resultsCss = css`
  margin: 10px;
  min-width: 400px;
  min-height: 400px;
`;
