/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Fragment } from "react";
import { Link } from "@reach/router";

import { colorsUse, alternateBorders } from "../css/css";
import LogoIcon from "../icons/LogoIcon.png";
import React from "react";

export function AppNavBar() {
  // const [menuOpen, setMenuOpen] = useState(false);

  // const deviceSize = useMedia();

  return (
    <Fragment>
      <nav css={appNavBarContainerCss} data-testid="nav_bar">
        <Link to="/home" css={logoContainerCss}>
          <img
            src={LogoIcon}
            style={{ height: 40, marginLeft: 10, color: "black" }}
            alt="Home Logo"
          />
          <div css={logoTextCss}>Rev Chess</div>
        </Link>
      </nav>
    </Fragment>
  );
}
const logoContainerCss = css`
  display: flex;
  align-items: top;
  text-decoration: none;
`;

const logoTextCss = css`
  margin-left: 5px;
  color: ${colorsUse.defaultLink};
  font-family: Inter, monospace, Arial, Helvetica, sans-serif;
  font-style: italic;
  font-weight: 800;
`;

// const hideableMenuContainerCss = css`
//   display: flex;
//   flex-direction: column;
//   align-items: flex-start;
//   border: 1px solid ${colors.DarkGrey};
//   ${alternateBorders()};
// `;

const appNavBarContainerCss = css`
  position: sticky;
  top: 0;
  left: 0;
  margin: auto;
  margin-top: 10px;
  display: flex;
  height: 40px;
  background-color: white;
  justify-content: left;
  align-items: top;
  ${alternateBorders()}
`;

// const navBarMenuButtonStyling = css`
//   color: ${colorsUse.defaultLink};
//   margin-left: 10px;
//   margin-right: 10px;
//   height: 20px;
// `;

// const menuItemsContainerStyling = css`
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: left;
//   align-items: flex-start;
//   margin-right: 10px;
//   ${alternateBorders()};
// `;
